import React from 'react';
import cn from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
  TitleSizeType,
} from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { CallToAction } from '../../../CallToActionGroup';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import SubsectionItem from './SubsectionItem';
import { ModuleHeader } from '../../components';
import styles from './StatisticsModule.module.scss';

export enum HubStatisticModuleSectionNumberFormat {
  COMMA = 'COMMA',
  DOT = 'DOT',
  SPACE = 'SPACE',
  NONE = 'NONE',
}

export type SubsectionItemType = {
  animated: boolean;
  title: string;
  body: string;
  titlePrefix: string;
  titleSuffix: string;
  countUpFrom: string;
  numberFormat: HubStatisticModuleSectionNumberFormat;
};

export interface StatisticsModuleType extends HubModule {
  '@type': HubModuleTypes.Statistics;
  subtitle: string;
  title: string;
  titleSize: TitleSizeType;
  columnStyle: string;
  subsections: SubsectionItemType[];
  link: HubModuleCTA;
}

export interface StatisticsModuleProps {
  module: StatisticsModuleType;
}

export default function StatisticsModule({
  module: {
    '@type': type,
    title,
    titleSize,
    subtitle,
    link,
    subsections,
    elementId,
    visualStyle,
  },
  testId,
}: PropsWithTestId<StatisticsModuleProps>) {
  const subsectionsClassName = cn({
    [styles.subsectionWrapper]: subsections.length === 0,
  });

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row middle-md">
          <div className="col-xs-12">
            <ModuleHeader
              title={title}
              titleSize={titleSize}
              text={subtitle}
              lineAfterParagraph
            />
          </div>
        </div>
        <div className={subsectionsClassName}>
          <div className="row">
            {subsections
              .filter((item) => (item.animated ? item.title : true))
              .map((item, index) => (
                <div key={index} className="col-xs-12 col-sm-12 col-lg-4">
                  <SubsectionItem {...item} />
                </div>
              ))}
          </div>
        </div>
        {link?.type && (
          <div className="row middle-md">
            <div className="col-xs-12">
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                className={styles.ctaButton}
              />
            </div>
          </div>
        )}
      </div>
    </HubModuleWrapper>
  );
}

StatisticsModule.defaultProps = {
  testId: 'hub-statistics-module',
};
